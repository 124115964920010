<div [ngClass]="{ 'beta-theme': isBeta, 'staging-theme': isStaging }">
  <mat-toolbar
    [ngClass]="{ 'staging-theme': isStaging }"
    color="primary"
    fxLayout="row"
    fxLayoutAlign="space-between center"
  >
    <div class="logo-cont" [routerLink]="['/', 'dashboard']" fxLayoutAlign="space-between bottom">
      <img src="../../../assets/images/logo.png" />
      <span class="version">{{ version }} {{ isBeta ? 'BETA' : isStaging ? 'STAGING' : '' }} </span>
    </div>

    <nav>
      <a routerLinkActive="active" [routerLink]="['/', 'dashboard', 'schedule', 'list']"> Home </a>
      <a routerLinkActive="active" [routerLink]="['/', 'dashboard', 'patient', 'chat-dashboard', 'dashboard']">
        Unread <span class="unreadCount">({{ unreadCount | async }})</span>
      </a>
      <div class="patient-search--container">
        <app-autocomplete-search-input
          (resultSelected)="goToPatient($event)"
          [searchServiceMethod]="patientSearch"
          placeholderText="Search for a Patient"
          tooltip="Name, ID, or DOB MM/DD/YYYY"
          [width]="'250px'"
          [clearSearchAfterSelection]="true"
          [disableLabelFloat]="true"
          [suppressError]="true"
        >
          <ng-template let-result>
            <div mat-line>
              {{ result.label }}
            </div>
            <div mat-line>
              {{ result.dateOfBirth | date }}
            </div>
          </ng-template>
        </app-autocomplete-search-input>
      </div>
      <a (click)="logout()">Logout</a>
    </nav>
  </mat-toolbar>
  <mat-progress-bar *ngIf="!this.navigationComplete" [mode]="'indeterminate'"></mat-progress-bar>
</div>
