import React from 'react';
import { Button, ButtonProps } from '@mui/material';

export function OutlinedButton({ children, ...props }: ButtonProps): React.JSX.Element {
  return (
    <Button variant='outlined' {...props}>
      {children}
    </Button>
  );
}

export default OutlinedButton;
