<mat-sidenav-container>
  <mat-sidenav color="accent" mode="side" opened [ngClass]="{ opened: (opened | async), closed: !(opened | async) }">
    <div class="nav-header">
      <button (click)="navigationService.toggleSidenav()" mat-icon-button>
        <mat-icon>{{ (opened | async) ? 'keyboard_arrow_left' : 'keyboard_arrow_right' }}</mat-icon>
      </button>
      <h1 *ngIf="opened | async" class="sidenav-header">My Dashboard</h1>
    </div>
    <div fxLayout="column" fxLayoutAlign="space-between start">
      <button
        mat-icon-button
        class="nav-button"
        [ngClass]="{ 'mat-icon-button': !(opened | async) }"
        routerLinkActive="active"
        [routerLink]="['/', 'dashboard', 'schedule', 'list']"
      >
        <mat-icon>event</mat-icon>
        <span *ngIf="opened | async">Appointments</span>
      </button>
      <button
        mat-icon-button
        class="nav-button"
        [ngClass]="{ 'mat-icon-button': !(opened | async) }"
        routerLinkActive="active"
        [routerLink]="['/', 'dashboard', 'patient', 'chat-dashboard']"
      >
        <mat-icon>forum</mat-icon>
        <span
          *ngIf="opened | async"
          matBadge="{{ unreadCount | async }}"
          matBadgeHidden="{{ !(unreadCount | async) > 0 }}"
          matBadgeColor="warn"
          matBadgeOverlap="false"
          matBadgePosition="after"
          >Chat Dashboard</span
        >
      </button>
      <button
        mat-icon-button
        class="nav-button"
        [ngClass]="{ 'mat-icon-button': !(opened | async) }"
        routerLinkActive="active"
        [routerLink]="['/dashboard/tasks']"
      >
        <mat-icon>task_alt</mat-icon>
        <span>Tasks</span>
      </button>
      <button
        mat-icon-button
        class="nav-button"
        [ngClass]="{ 'mat-icon-button': !(opened | async) }"
        routerLinkActive="active"
        [routerLink]="['/', 'patients']"
      >
        <mat-icon>face</mat-icon>
        <span [hidden]="!(opened | async)">Patients</span>
      </button>
      <button
        mat-icon-button
        class="nav-button"
        [ngClass]="{ 'mat-icon-button': !(opened | async) }"
        routerLinkActive="active"
        [routerLink]="['/', 'dashboard', 'membership', 'list']"
      >
        <mat-icon>group</mat-icon>
        <span *ngIf="opened | async">Memberships</span>
      </button>
      <button
        mat-icon-button
        class="nav-button"
        [ngClass]="{ 'mat-icon-button': !(opened | async) }"
        routerLinkActive="active"
        [routerLink]="['/', 'dashboard', 'membership', 'types']"
      >
        <mat-icon>card_membership</mat-icon>
        <span *ngIf="opened | async">Membership Types</span>
      </button>
      <button
        mat-icon-button
        class="nav-button"
        [ngClass]="{ 'mat-icon-button': !(opened | async) }"
        routerLinkActive="active"
        [routerLink]="['/', 'dashboard', 'company']"
      >
        <mat-icon>business</mat-icon>
        <span *ngIf="opened | async">Employers</span>
      </button>

      <button
        mat-icon-button
        class="nav-button"
        [ngClass]="{ 'mat-icon-button': !(opened | async) }"
        routerLinkActive="active"
        [routerLink]="['/', 'dashboard', 'markets']"
      >
        <mat-icon>store</mat-icon>
        <span *ngIf="opened | async">Markets</span>
      </button>
      <button
        *ngIf="!isUsingIdentityService; else useIdentityServiceButton"
        mat-icon-button
        class="nav-button"
        [ngClass]="{ 'mat-icon-button': !(opened | async) }"
        routerLinkActive="active"
        [routerLink]="['/', 'dashboard', 'provider', 'list']"
      >
        <mat-icon>assignment_ind</mat-icon>
        <span *ngIf="opened | async">Users</span>
      </button>
      <ng-template #useIdentityServiceButton>
        <button
          mat-icon-button
          class="nav-button"
          [ngClass]="{ 'mat-icon-button': !(opened | async) }"
          (click)="onUsersToolClicked()"
        >
          <mat-icon>assignment_ind</mat-icon>
          <span *ngIf="opened | async">Users</span>
        </button>
      </ng-template>
      <button
        mat-icon-button
        class="nav-button"
        [ngClass]="{ 'mat-icon-button': !(opened | async) }"
        routerLinkActive="active"
        [routerLink]="['/', 'dashboard', 'billing']"
      >
        <mat-icon>description</mat-icon>
        <span *ngIf="opened | async">Invoices</span>
      </button>
      <button
        mat-icon-button
        class="nav-button"
        [ngClass]="{ 'mat-icon-button': !(opened | async) }"
        routerLinkActive="active"
        [routerLink]="['/', 'dashboard', 'schedule', 'home-visit-request', 'list']"
      >
        <mat-icon>bookmark</mat-icon>
        <span
          *ngIf="opened | async"
          matBadge="{{ unviewedCount | async }}"
          matBadgeColor="warn"
          matBadgeHidden="{{ (unviewedCount | async) > 0 ? false : true }}"
          matBadgeOverlap="false"
          matBadgePosition="after"
          >Scheduling Requests</span
        >
      </button>
      <button
        mat-icon-button
        class="nav-button"
        [ngClass]="{ 'mat-icon-button': !(opened | async) }"
        routerLinkActive="active"
        [routerLink]="['/dashboard/fax']"
        [queryParams]="{ active: 'inbox' }"
      >
        <mat-icon>send</mat-icon>
        <span>Digital Faxes</span>
      </button>
    </div>
  </mat-sidenav>
  <mat-sidenav-content [ngClass]="{ 'sidenav-closed--content': !(opened | async) }">
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
