import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { version } from '../../../../../core/version';
import { AuthService } from '../../core/services/auth.service';
import { ChatService } from '../../core/services/chat.service';
import { NavigationCancel, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject, ReplaySubject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { PatientService } from '../../core/services/patient.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavComponent implements OnInit, OnDestroy {
  public isBeta = environment.isBeta;
  public isStaging = environment.isStaging;

  public unreadCount: ReplaySubject<number>;
  public version = version.current;
  public navigationComplete = true;
  public patientSearch: any;

  public environment = environment;

  private destroyed: Subject<boolean> = new Subject();

  constructor(
    private authService: AuthService,
    private chatService: ChatService,
    private router: Router,
    private patientService: PatientService
  ) {
    this.unreadCount = this.chatService.unreadMessageCount;
    this.patientSearch = this.patientService.searchForPatientByNameOrIdOrDobNav.bind(this.patientService);
  }

  ngOnInit() {
    this.router.events
      .pipe(
        filter(
          (event) =>
            event instanceof NavigationStart || event instanceof NavigationEnd || event instanceof NavigationCancel
        ),
        map((event) => event instanceof NavigationEnd || event instanceof NavigationCancel),
        takeUntil(this.destroyed)
      )
      .subscribe((navigationEnd) => (this.navigationComplete = navigationEnd));
  }

  ngOnDestroy() {
    this.destroyed.next(true);
  }

  goToPatient(patient) {
    this.router.navigate(['/', 'patients', 'view', patient.id]);
  }

  async logout(): Promise<void> {
    this.authService.logout();
  }
}
