import React, { PropsWithChildren } from 'react';
import ElationChartButton from './ElationChartButton';
import ThemedComponent from '../../../theme/ThemedComponent';
import { PatientService } from '../../../../core/services/patient.service';
import { Patient } from '@models';

type ElationChartButtonWrapperProps = PropsWithChildren<{
  patient: Patient;
  patientService: PatientService;
}>;

export function ElationChartButtonWrapper({
  patient,
  patientService,
}: ElationChartButtonWrapperProps): React.JSX.Element {
  return (
    <ThemedComponent>
      <ElationChartButton patient={patient} patientService={patientService} />
    </ThemedComponent>
  );
}

export default ElationChartButtonWrapper;
