import { authzRolesStaging } from '../../../core/constants/authz-roles';
import { Environment } from './environment.type';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment: Environment = {
  environmentLabel: 'staging',
  production: true,
  isStaging: true,
  niceAdminUrl: 'https://provider.web.staging.noice.healthcare',
  socketUrl: 'https://vanity-api.noice.healthcare/legacy',
  niceServiceUrl: 'https://vanity-api.noice.healthcare/api',
  apiUrl: 'https://vanity-api.noice.healthcare/legacy/api/v1',
  apiV2Url: 'https://vanity-api.noice.healthcare/legacy/api/v2',
  apiV3Url: 'https://vanity-api.noice.healthcare/legacy/api/v3',
  returnToUrl: 'https://provider.legacy.staging.noice.healthcare',
  elationUrl: 'https://sandbox.elationemr.com',
  chatTagPollingRate: 8000,
  chatPollingRate: 5000,
  unreadFaxPollingRate: 5000,
  chatPollingRateBackground: 10000,
  taskCountPollingRate: 5000,
  homeVisitRequestPollingRate: 5000,
  featuresEnabled: {
    referral: true,
    mentalHealthChart: true,
    elation: true,
    documents: true,
    internalChat: false,
  },
  auth0Roles: authzRolesStaging,
  googleMapsPublicApiKey: 'AIzaSyAnAihik9Su3eiLXEzSvbp_CktVFruxads', // nosemgrep: generic.secrets.security.detected-google-cloud-api-key.detected-google-cloud-api-key
  changeHealthcareSSOUrl: 'https://cli-cert.changehealthcare.com/servlet/DxLogin',
  changeHealthcareNiceBusinessId: 3004400962,
  amplitudeUrl: 'https://analytics.amplitude.com/nice-healthcare/project/402196/search/user_id%3D',
  auth0Url: 'https://manage.auth0.com/dashboard/us/nice-healthcare-staging/users/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
